import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { loginUser } from '../../../store/slices/authSlice';

const Authenticate = ({ children }: { children: any }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const expiresIn = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 3);
    const getTokenInLocalStorage = localStorage.getItem('token');
    const tokenObject = JSON.parse(getTokenInLocalStorage ? getTokenInLocalStorage : '[]');
    const remainingTime = new Date(tokenObject.expiresIn).getTime() - new Date().getTime();

    const initialize = async (): Promise<void> => {
      try {
        if (tokenObject.token && remainingTime > 0) {
          localStorage.setItem(
            'token',
            JSON.stringify({
              expiresIn: expiresIn,
              token: tokenObject.token,
            }),
          );

          const response = await axios.get<{ user: any; status: string; token: string }>(
            `${process.env.REACT_APP_BASE_URL}/api/v1/auth/get-user`,
            {
              headers: {
                Authorization: `Bearer ${tokenObject.token}`,
              },
            },
          );
          if (response.data.status === 'success') {
            const { user } = response.data;
            dispatch(loginUser({ token: tokenObject.token, user, isLoggedIn: true, isInitialized: true }));
          } else {
            dispatch(loginUser({ token: '', user: {}, isLoggedIn: false, isInitialized: true }));
          }
        } else {
          dispatch(loginUser({ token: '', user: {}, isLoggedIn: false, isInitialized: true }));
        }
      } catch (err) {
        dispatch(loginUser({ token: '', user: {}, isLoggedIn: false, isInitialized: true }));
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default Authenticate;
