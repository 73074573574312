import React, { Suspense } from 'react';
import { Box } from '@mui/material';
import LoadingCircle from '../loading-circle';

const Loadable = (Component: React.FC) => (props: any) =>
  (
    <Suspense
      fallback={
        <Box
          sx={{
            minHeight: '600px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingCircle />
        </Box>
      }
    >
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
