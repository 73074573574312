import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const LoadingCircle = ({ sx }: { sx?: any }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={sx ? { ...sx } : { height: '66px' }}
  >
    <CircularProgress />
  </Box>
);

export default LoadingCircle;
