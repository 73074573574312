import { createSlice } from '@reduxjs/toolkit';
import { alert } from '../types';

const initialState: alert = {
  isOpen: false,
  message: '',
  type: 'error',
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    show(state, action) {
      state.isOpen = action.payload.isOpen;
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
    close(state) {
      state.isOpen = false;
    },
  },
});

export const { show, close } = alertSlice.actions;
export default alertSlice.reducer;
