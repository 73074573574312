import { dataTypes, RegularExpression } from '../types';

export const DATA_TYPES: dataTypes = {
  mdy: 'YYYY-MM-DD',
  ll: 'll',
  mdyt: 'YYYY-MM-DD HH:mm',
};

export const REGULAR_EXPRESSION: RegularExpression = {
  phone: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g, // eslint-disable-line
  nameAndEmail: /^[AaBbCcÇçDdEeƏəFfGgĞğHhXxIıİiJjKkQqLlMmNnOoÖöPpRrSsŞşTtUuÜüVvYyZzWw]+$/,
  decimalNumber: /^\d+\.?\d*$/,
  integer: /^\d+$/,
};

export const routers = [
  {
    path: '',
    name: 'home',
    url: '/',
  },
  {
    path: 'add-book',
    name: 'add book',
    url: '/books/add',
  },
  {
    path: 'create',
    name: 'create Book',
    url: '/book/create',
  },
  {
    path: 'sale',
    name: 'sale book',
    url: '/book/sale',
  },
  {
    path: 'books',
    name: 'books',
    url: '/books',
  },
  {
    path: 'account',
    name: 'account',
    url: '/account',
  },
  {
    path: 'add-transaction',
    name: 'add transaction',
    url: '/add-transaction',
  },
  {
    path: 'transactions',
    name: 'transactions',
    url: '/transactions',
  },
  {
    path: 'add-internal-transaction',
    name: 'add internal transaction',
    url: '/add-internal-transaction',
  },
  {
    path: 'add-expense',
    name: 'add expense',
    url: '/add-expense',
  },
  {
    path: 'students',
    name: 'students',
    url: '/students',
  },
  {
    path: 'add-student',
    name: 'add student',
    url: '/add-student',
  },
  {
    path: 'lesson-count',
    name: 'lesson count',
    url: '/lesson-count',
  },
  {
    path: 'employees',
    name: 'employees',
    url: '/employees',
  },
  {
    path: 'teachers-earnings',
    name: 'teachers earnings',
    url: '/teachers-earnings',
  },
  {
    path: 'manage-earning',
    name: 'manage earning',
    url: '',
  },
  {
    path: 'manage-permissions',
    name: 'employees',
    url: '/employees',
  },
  {
    path: 'cash-registers',
    name: 'cash registers',
    url: '/cash-registers',
  },
  {
    path: 'add-cash-register',
    name: 'add cash register',
    url: '/cash-registers/add-cash-register',
  },
  {
    path: 'conversations',
    name: 'conversations',
    url: '/conversations',
  },
  {
    path: 'add-conversation',
    name: 'add conversation ',
    url: '/conversations/add-conversation',
  },
  {
    path: 'groups',
    name: 'groups',
    url: '/groups',
  },
  {
    path: 'add-group',
    name: 'add group',
    url: '/groups/add-group',
  },
  {
    path: ':id',
    name: 'conversation detail',
    url: '',
  },
  {
    path: 'certificates',
    name: 'certificates',
    url: '/certificates',
  },
  {
    path: 'add-certificate',
    name: 'add certificate',
    url: '/certificates/add-certificate',
  },
  {
    path: 'categories',
    name: 'categories',
    url: '/categories',
  },
  {
    path: 'permissions',
    name: 'permissions',
    url: '/permissions',
  },
  {
    path: 'lessons',
    name: 'lessons',
    url: '/lessons',
  },
  {
    path: 'internal-transactions',
    name: 'internal transactions',
    url: '/internal-transactions',
  },
  {
    path: 'company-details',
    name: 'company details',
    url: '/company-details',
  },
  {
    path: 'repeated-payments',
    name: 'repeated payments',
    url: '/repeated-payments',
  },
  {
    path: 'inventory',
    name: 'inventory',
    url: '/inventory',
  },
  {
    path: 'add-inventory',
    name: 'add inventory',
    url: '/inventory/add-inventory',
  },
];
