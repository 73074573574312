import { useRoutes } from 'react-router-dom';
import Layout from '../shared/components/layout/Layout';

// react
import { useSelector } from 'react-redux';
import { Store } from '../store/types';

//routes
import AuthRoutes from './auth';
import getPermittedRoutes from './mainAuthed';

export default function AppRouter() {
  const token = useSelector((state: Store) => state.auth.token);
  const { permissions } = useSelector((state: Store) => state.auth.user);
  const routes = useRoutes(token ? getPermittedRoutes(permissions as any) : AuthRoutes);

  return <Layout isLoggedIn={token}>{routes}</Layout>;
}
