import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import { FormHelperText } from '@mui/material';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../../store/slices/authSlice';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { useMutation } from 'react-query';
import axios from 'axios';
import { loginFormData } from '../types';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const initialValues = {
  email: '',
  password: '',
};

const validationSchema: SchemaOf<loginFormData> = Yup.object().shape({
  email: Yup.string().email('Email not entered correctly').required('Email is required'),
  password: Yup.string()
    // .min(6, 'Şifrə minimum 6 simvoldan ibarət olunmalıdır')
    .required('Password is required'),
});

const loginRequest = async (data: any) => {
  const { data: userData } = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/login`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return userData;
};

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { mutate: sendLoginRequest, isLoading } = useMutation('login', loginRequest, {
    onSuccess: (data) => {
      dispatch(loginUser({ token: data.token, user: data.user, isLoggedIn: true, isInitialized: true }));
    },
    onError: () => {
      setOpen(true);
    },
  });

  const formik = useFormik({
    initialValues,
    onSubmit: (values: any) => {
      sendLoginRequest(values);
    },
    validationSchema,
  });

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Container>
      <Box component="div" display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
        <Box
          sx={{
            width: { sm: '500px', xs: '100%' },
            backgroundColor: '#fff',
            p: 3,
            borderRadius: '10px',
            border: '1px solid rgba(0, 0, 0, 0.2)',
          }}
        >
          <Box
            textAlign="center"
            sx={{
              img: {
                width: '53px',
                height: '38px',
              },
            }}
          >
            <img src={process.env.REACT_APP_LOGO} alt="" />
          </Box>
          <Box component="div" textAlign="center" sx={{ my: 2 }}>
            <Typography>Login by email</Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <FormControl variant="filled" fullWidth error={formik.touched.email && Boolean(formik.errors.email)} sx={{ height: '79px' }}>
              <InputLabel htmlFor="email">Email</InputLabel>
              <FilledInput
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{ backgroundColor: '#fff' }}
                value={formik.values.email}
              />
              {formik.touched.email && Boolean(formik.errors.email) && (
                <FormHelperText sx={{ ml: 0 }}>{formik.errors.email}</FormHelperText>
              )}
            </FormControl>
            <FormControl
              sx={{ height: '79px' }}
              variant="filled"
              fullWidth
              error={formik.touched.password && Boolean(formik.errors.password)}
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <FilledInput
                id="password"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type={showPassword ? 'text' : 'password'}
                style={{ backgroundColor: '#fff' }}
                value={formik.values.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" edge="end" onClick={handlePassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik.touched.password && Boolean(formik.errors.password) && (
                <FormHelperText sx={{ ml: 0 }}>{formik.errors.password}</FormHelperText>
              )}
            </FormControl>
            {isLoading ? (
              <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '66px' }}>
                <CircularProgress />
              </Box>
            ) : (
              <Button variant="contained" fullWidth sx={{ mt: 3 }} size="large" type="submit">
                Login
              </Button>
            )}
          </form>
        </Box>
      </Box>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Email or password is incorrect!
          </Alert>
        </Snackbar>
      </Stack>
    </Container>
  );
};

export default Login;
