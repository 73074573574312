import * as React from 'react';

import BreadcrumbsContainer from '../breadcrumbs/breadcrumbs';
import Drawer from './Drawer';
import Header from './Header';
import Alert from '../alert/alert';
import { CssBaseline, Box } from '@mui/material';

const Layout: React.FC<{ isLoggedIn: boolean | string }> = ({ children, isLoggedIn }) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen((s) => !s);
  };

  return (
    <>
      <CssBaseline />
      {isLoggedIn && (
        <>
          <Header toggleDrawer={toggleDrawer} />
          <BreadcrumbsContainer />
          <Drawer isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
        </>
      )}

      <Box component="main" sx={{ px: 3 }}>
        {children}
      </Box>
      <Alert />
    </>
  );
};
export default Layout;
