import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PaymentsIcon from '@mui/icons-material/Payments';
import SchoolIcon from '@mui/icons-material/School';
import PersonIcon from '@mui/icons-material/Person';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import GroupsIcon from '@mui/icons-material/Groups';
import ArticleIcon from '@mui/icons-material/Article';
import TuneIcon from '@mui/icons-material/Tune';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import BookIcon from '@mui/icons-material/Book';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import TerminalIcon from '@mui/icons-material/Terminal';
import InfoIcon from '@mui/icons-material/Info';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import InventoryIcon from '@mui/icons-material/Inventory';

export const getNavigationItems = (permissions: string[]): any[] => {
  const routes = [
    {
      id: 'dashboard',
      label: 'Dashboard',
      icon: AnalyticsIcon,
      path: '/dashboard',
    },
    {
      id: 'books',
      label: 'Books',
      icon: LibraryBooksIcon,
      path: '/books',
    },
    {
      id: 'add-transaction',
      label: 'Add transaction',
      icon: PaymentsIcon,
      path: '/add-transaction',
    },
    {
      id: 'add-expense',
      label: 'Add expense',
      icon: PaymentsIcon,
      path: '/add-expense',
    },
    {
      id: 'tansactions',
      label: 'Transactions',
      icon: CompareArrowsIcon,
      path: '/transactions',
    },
    {
      id: 'add-internal-transaction',
      label: 'transfer payment',
      icon: PaymentsIcon,
      path: '/add-internal-transaction',
    },
    {
      id: 'students',
      label: 'Students',
      icon: SchoolIcon,
      path: '/students',
    },
    {
      id: 'add-student',
      label: 'Add Student',
      icon: AddBoxIcon,
      path: '/add-student',
    },
    {
      id: 'employees',
      label: 'Employees',
      icon: PersonIcon,
      path: '/employees',
    },
    {
      id: 'cashRegisters',
      label: 'Cash registers',
      icon: AttachMoneyIcon,
      path: '/cash-registers',
    },
    {
      id: 'conversations',
      label: 'Conversations',
      icon: RecordVoiceOverIcon,
      path: '/conversations',
    },
    {
      id: 'certificates',
      label: 'Certificates',
      icon: ArticleIcon,
      path: '/certificates',
    },
    {
      id: 'lessons',
      label: 'Lessons',
      icon: BookIcon,
      path: '/lessons',
    },
    {
      id: 'lesson-count',
      label: 'Lesson count',
      icon: HourglassTopIcon,
      path: '/lesson-count',
    },
    {
      id: 'contact',
      label: 'Contact',
      icon: ContactPhoneIcon,
      path: '/contact',
    },

    {
      id: 'company-details',
      label: 'Company details',
      icon: InfoIcon,
      path: '/company-details',
    },
    {
      id: 'repeated-payments',
      label: 'Repeated Payments',
      icon: EventRepeatIcon,
      path: '/repeated-payments',
    },
    {
      id: 'inventory',
      label: 'inventory',
      icon: InventoryIcon,
      path: '/inventory',
    },
    {
      id: 'appManager',
      label: 'App manager',
      icon: TuneIcon,
      path: '/categories',
      children: [
        {
          id: 'Categories',
          label: 'Categories',
          icon: ViewComfyIcon,
          path: '/categories',
        },
        {
          id: 'programs',
          label: 'Programs',
          icon: TerminalIcon,
          path: '/programs',
        },
      ],
    },
  ];

  if (permissions.includes('groups')) {
    routes.splice(9, 0, {
      id: 'groups',
      label: 'Groups',
      icon: GroupsIcon,
      path: '/groups',
    });
  }

  if (permissions.includes('internal-transactions')) {
    routes.splice(4, 0, {
      id: 'internal-transactions',
      label: 'Internal transactions',
      icon: CompareArrowsIcon,
      path: '/internal-transactions',
    });
  }

  if (permissions.includes('permissions-module')) {
    routes[routes.length - 1].children?.push({
      id: 'Permissions',
      label: 'Permissions',
      icon: LockOpenIcon,
      path: '/permissions',
    });
  }

  return routes;
};
