import { createTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_COMPANY_PRIMARY_COLOR || '',
    },
    secondary: {
      main: green[500],
    },
    background: {
      default: '#f5f5f5',
    },
    success: {
      main: '#57CA22',
    },
    warning: {
      main: '#FFA319',
    },
    error: {
      main: '#FF1943',
    },
    info: {
      main: '#33C2FF',
    },
  },
});
export default theme;
