import { Box } from '@mui/material';

function AppInit() {
  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        textAlign="center"
        sx={{
          img: {
            width: '53px',
            height: '38px',
          },
        }}
      >
        <img src={process.env.REACT_APP_LOGO} alt="" />
      </Box>
    </Box>
  );
}

export default AppInit;
