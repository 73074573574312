import { lazy } from 'react';
import { Navigate } from 'react-router';
import Loadable from '../shared/components/laodable/loadable';

//components
const Dashboard = Loadable(lazy(() => import('../modules/dashboard/pages/dashboard')));
const Books = Loadable(lazy(() => import('../modules/books/containers/books')));
const AddBook = Loadable(lazy(() => import('../modules/books/pages/add-book')));
const Sales = Loadable(lazy(() => import('../modules/books/pages/sales-book')));
const EditBook = Loadable(lazy(() => import('../modules/books/pages/edit-book')));
const CreateSale = Loadable(lazy(() => import('../modules/books/pages/create-sale')));
const BooksRouter = Loadable(lazy(() => import('../modules/books/router/books-router')));
const Account = Loadable(lazy(() => import('../modules/account/pages/account')));
const AddTransaction = Loadable(lazy(() => import('../modules/transactions/pages/add-transaction')));
const AddExpense = Loadable(lazy(() => import('../modules/transactions/pages/add-expense')));
const Transactions = Loadable(lazy(() => import('../modules/transactions/pages/transactions')));
const AddInternalTransaction = Loadable(lazy(() => import('../modules/transactions/pages/add-internal-transaction')));
const StudentsRouter = Loadable(lazy(() => import('../modules/students/router')));
const Students = Loadable(lazy(() => import('../modules/students/containers/students')));
const StudentInfoRouter = Loadable(lazy(() => import('../modules/students/router/student-info')));
const LessonsCount = Loadable(lazy(() => import('../modules/students/pages/lessons-count')));
const Addstundent = Loadable(lazy(() => import('../modules/students/pages/add-student')));
const EmployeesRouter = Loadable(lazy(() => import('../modules/employees/router')));
const Employees = Loadable(lazy(() => import('../modules/employees/containers/employees')));
const TeachersEarnings = Loadable(lazy(() => import('../modules/employees/pages/teachers-earnings')));
const EmployeeInfo = Loadable(lazy(() => import('../modules/employees/pages/employee-info')));
const EmployeeManageEarning = Loadable(lazy(() => import('../modules/employees/pages/employee-manage-earning')));
const CashRegisters = Loadable(lazy(() => import('../modules/cash-registers/pages/cash-registers')));
const AddCashRegister = Loadable(lazy(() => import('../modules/cash-registers/pages/add-cash-register')));
const ConversationsRouter = Loadable(lazy(() => import('../modules/conversations/router')));
const TransactionsRouter = Loadable(lazy(() => import('../modules/transactions/router')));
const Conversations = Loadable(lazy(() => import('../modules/conversations/pages/conversations')));
const AddConversation = Loadable(lazy(() => import('../modules/conversations/pages/add-conversation')));
const ConversationDetails = Loadable(lazy(() => import('../modules/conversations/pages/conversation-details')));
const PermissionsRouter = Loadable(lazy(() => import('../modules/permissions/router')));
const ManagePermissions = Loadable(lazy(() => import('../modules/permissions/pages/manage-permissions')));
const Permissions = Loadable(lazy(() => import('../modules/permissions/containers/permissions')));
const GroupsRouter = Loadable(lazy(() => import('../modules/groups/router/books-router')));
const Groups = Loadable(lazy(() => import('../modules/groups/pages/groups')));
const AddGroup = Loadable(lazy(() => import('../modules/groups/pages/add-group')));
const GroupDetail = Loadable(lazy(() => import('../modules/groups/pages/group-detail')));
const CertificatesRouter = Loadable(lazy(() => import('../modules/certificates/router/certificates-router')));
const Certificates = Loadable(lazy(() => import('../modules/certificates/pages/certificates')));
const EditCertificate = Loadable(lazy(() => import('../modules/certificates/pages/edit-certificate')));
const AddCertificate = Loadable(lazy(() => import('../modules/certificates/pages/add-certificate')));
const Categories = Loadable(lazy(() => import('../modules/categories/containers/categories')));
const LessonsRouter = Loadable(lazy(() => import('../modules/lessons/router')));
const Lessons = Loadable(lazy(() => import('../modules/lessons/containers/lessons')));
const LessonsInfo = Loadable(lazy(() => import('../modules/lessons/pages/lessons-info')));
const InternalTransactionsRouter = Loadable(lazy(() => import('../modules/internal-transactions/router')));
const InternalTransactionsContainer = Loadable(lazy(() => import('../modules/internal-transactions/containers/internal-transactions')));
const InternalTransactionsInfo = Loadable(lazy(() => import('../modules/internal-transactions/pages/internal-transaction-info')));
const Contact = Loadable(lazy(() => import('../modules/contact/containers/contact')));
const Program = Loadable(lazy(() => import('../modules/program/containers/program')));
const CompantDetails = Loadable(lazy(() => import('../modules/company-details/pages/company-details')));
const LessonCounts = Loadable(lazy(() => import('../modules/lessons-count/containers/lessons-count')));
const RepeatedPayments = Loadable(lazy(() => import('../modules/repeated-payments/containers/repeated-payments')));
const Inventory = Loadable(lazy(() => import('../modules/inventory/containers/inventory')));
const InventoryRouter = Loadable(lazy(() => import('../modules/inventory/router/inventory-router')));
const AddInventory = Loadable(lazy(() => import('../modules/inventory/pages/add-inventory')));
const EditInventory = Loadable(lazy(() => import('../modules/inventory/pages/edit-inventory')));

const getPermittedRoutes = (permissions: any) => {
  const routes = [
    {
      path: '/dashboard',
      element: <Dashboard />,
    },
    {
      path: 'books',
      element: <BooksRouter />,
      children: [
        {
          path: 'add-book',
          element: <AddBook />,
        },
        permissions.includes('sales') && {
          path: 'sales',
          element: <Sales />,
        },
        {
          path: 'create',
          element: <AddBook type="create" />,
        },
        {
          path: 'sale',
          element: <CreateSale type="create" />,
        },
        {
          path: '/books',
          element: <Books />,
        },
        {
          path: ':id',
          element: <EditBook />,
        },
      ],
    },
    {
      path: '/account',
      element: <Account />,
    },
    {
      path: '/add-transaction',
      element: <AddTransaction />,
    },
    {
      path: '/transactions',
      element: <TransactionsRouter />,
      children: [
        {
          path: '/transactions',
          element: <Transactions />,
        },
      ],
    },
    {
      path: 'internal-transactions',
      element: <InternalTransactionsRouter />,
      children: [
        {
          path: '/internal-transactions',
          element: <InternalTransactionsContainer />,
        },
        {
          path: ':id',
          element: <InternalTransactionsInfo />,
        },
      ],
    },
    {
      path: '/add-internal-transaction',
      element: <AddInternalTransaction />,
    },
    {
      path: '/add-expense',
      element: <AddExpense />,
    },
    {
      path: 'students',
      element: <StudentsRouter />,
      children: [
        {
          path: '/students',
          element: <Students />,
        },
        {
          path: ':id',
          element: <StudentInfoRouter />,
          children: [
            {
              path: ':id',
              element: <LessonsCount />,
            },
            {
              path: 'lessons-count',
              element: <LessonsCount />,
            },
          ],
        },
      ],
    },
    {
      path: 'add-student',
      element: <Addstundent />,
    },
    {
      path: 'employees',
      element: <EmployeesRouter />,
      children: [
        {
          path: '/employees',
          element: <Employees />,
        },
        permissions.includes('teacher-earning-overview') && {
          path: '/employees/teachers-earnings',
          element: <TeachersEarnings />,
        },
        {
          path: ':id',
          element: <EmployeeInfo />,
        },
        {
          path: ':id/manage-earning',
          element: <EmployeeManageEarning />,
        },
      ],
    },
    {
      path: '/cash-registers',
      element: <CashRegisters />,
      children: [
        {
          path: 'add-cash-register',
          element: <AddCashRegister />,
        },
      ],
    },
    {
      path: 'conversations',
      element: <ConversationsRouter />,
      children: [
        {
          path: '/conversations',
          element: <Conversations />,
        },
        {
          path: 'add-conversation',
          element: <AddConversation />,
        },
        {
          path: ':id',
          element: <ConversationDetails />,
        },
      ],
    },
    permissions.includes('permissions-module') && {
      path: 'permissions',
      element: <PermissionsRouter />,
      children: [
        {
          path: '/permissions',
          element: <Permissions />,
        },
      ],
    },
    permissions.includes('change-permission') && {
      path: 'manage-permissions',
      element: <PermissionsRouter />,
      children: [
        {
          path: '/manage-permissions',
          element: <Navigate to="/dashboard" />,
        },
        {
          path: ':id',
          element: <ManagePermissions />,
        },
      ],
    },
    permissions.includes('groups') && {
      path: 'groups',
      element: <GroupsRouter />,
      children: [
        permissions.includes('add-group') && {
          path: 'add-group',
          element: <AddGroup />,
        },
        {
          path: ':id',
          element: <GroupDetail />,
        },
        {
          path: '/groups',
          element: <Groups />,
        },
      ],
    },
    {
      path: 'certificates',
      element: <CertificatesRouter />,
      children: [
        {
          path: 'add-certificate',
          element: <AddCertificate />,
        },
        {
          path: '/certificates',
          element: <Certificates />,
        },
        {
          path: ':id',
          element: <EditCertificate />,
        },
      ],
    },
    {
      path: '/categories',
      element: <Categories />,
    },
    {
      path: 'lessons',
      element: <LessonsRouter />,
      children: [
        {
          path: '/lessons',
          element: <Lessons />,
        },
        {
          path: ':id',
          element: <LessonsInfo />,
        },
      ],
    },
    {
      path: 'inventory',
      element: <InventoryRouter />,
      children: [
        {
          path: '/inventory',
          element: <Inventory />,
        },
        {
          path: 'add-inventory',
          element: <AddInventory />,
        },
        {
          path: ':id',
          element: <EditInventory />,
        },
      ],
    },
    {
      path: 'contact',
      element: <Contact />,
    },
    {
      path: 'programs',
      element: <Program />,
    },
    {
      path: 'lesson-count',
      element: <LessonCounts />,
    },
    {
      path: 'company-details',
      element: <CompantDetails />,
    },
    {
      path: 'repeated-payments',
      element: <RepeatedPayments />,
    },
    {
      path: '*',
      element: <Navigate to="/dashboard" />,
    },
  ];

  const filteredRoutes = routes.filter((route) => {
    return route;
  });

  return filteredRoutes;
};

export default getPermittedRoutes;
