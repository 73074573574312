import { useLocation } from 'react-router';
import { Link as link } from 'react-router-dom';

import { Breadcrumbs } from '@mui/material';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import { routers } from '../../costants/constants';

export default function BreadcrumbsContainer() {
  const location = useLocation();

  return (
    <Paper sx={{ m: 3, p: 2 }}>
      <Breadcrumbs aria-label="breadcrumb">
        {location.pathname === '/' ? (
          <Link underline="hover" color="inherit" component={link} to="/">
            home
          </Link>
        ) : (
          location.pathname.split('/').map((name) => {
            const data: any = routers.filter((router) => router.path === name);
            if (data[0]) {
              return (
                <Link
                  underline="hover"
                  color="inherit"
                  key={name}
                  component={link}
                  to={data.length !== 0 ? (data[0].url ? data[0].url : location.pathname) : ''}
                >
                  {data.length !== 0 ? data[0].name : ''}
                </Link>
              );
            } else {
              const locationArray: string[] = location.pathname.split('/');
              const path = locationArray.filter((path) => path !== locationArray[locationArray.length - 1]).join('/');
              return (
                <Link
                  underline="hover"
                  color="inherit"
                  key={name}
                  component={link}
                  to={locationArray.length === 3 ? location.pathname : path}
                >
                  {name}
                </Link>
              );
            }
          })
        )}
      </Breadcrumbs>
    </Paper>
  );
}
