import ReactDOM from 'react-dom';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';
import theme from './shared/theme/theme';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import Authenticate from './shared/components/authenticate';

//redux
import { Provider } from 'react-redux';
import store from './store';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, retry: false },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Authenticate>
          <Router>
            <App />
          </Router>
        </Authenticate>
      </QueryClientProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
