import { createSlice } from '@reduxjs/toolkit';
import { auth } from '../types';
const expiresIn = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 3);

const initialState: auth = {
  token: '',
  user: {},
  isLoggedIn: false,
  isInitialized: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUser(state, action) {
      localStorage.setItem(
        'token',
        JSON.stringify({
          expiresIn: expiresIn,
          token: action.payload.token,
        }),
      );
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isInitialized = action.payload.isInitialized;
    },
    logoutUser(state) {
      localStorage.removeItem('token');
      state.user = {};
      state.token = '';
    },
    changePermission(state, action) {
      state.user = {
        ...state.user,
        permissions: action.payload.permissions,
      };
    },
  },
});

export const { loginUser, logoutUser, changePermission } = authSlice.actions;
export default authSlice.reducer;
