import { Navigate } from 'react-router';

//component
import Login from '../modules/auth/pages/login';

const AuthRoutes = [
  {
    path: '/auth/login',
    element: <Login />,
  },
  {
    path: '*',
    element: <Navigate to="/auth/login" />,
  },
];

export default AuthRoutes;
