import AppRouter from './routes';
import { useEffect } from 'react';
import AppInit from './shared/components/appInit';
import { useSelector } from 'react-redux';
import { Store } from './store/types';

const App = () => {
  const auth = useSelector((state: Store) => state.auth);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_COMPANY_NAME}`;
  }, []);
  return <>{auth.isInitialized ? <AppRouter /> : <AppInit />}</>;
};

export default App;
