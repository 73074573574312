import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/authSlice';
import alertSlice from './slices/alertSlice';

const rootReducer = {
  auth: authSlice,
  alert: alertSlice,
};

export default configureStore({ reducer: rootReducer });
