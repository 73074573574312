import * as React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { getNavigationItems } from './constants';
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { colors, Collapse, ListItemButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { Store } from '../../../store/types';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const StyledLink = styled(Link)(({ theme }) => ({
  color: colors.grey[900],
  textDecoration: 'none',
  display: 'flex',
}));

const CollapseLink = ({ parentLink: { label, icon: Icon, path, id, children }, handleDrawer }: any) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List component="div" disablePadding>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={label.toLowerCase()} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children.map(({ label, icon: Icon, path, id, children }: any) => (
          <StyledLink to={path} onClick={handleDrawer} key={id}>
            <ListItem button>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={label.toLowerCase()} />
            </ListItem>
          </StyledLink>
        ))}
      </Collapse>
    </List>
  );
};

const Drawer: React.FC<{
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
}> = ({ isDrawerOpen, toggleDrawer }) => {
  const { permissions } = useSelector((state: Store) => state.auth.user);
  const list = (
    <List sx={{ width: 250 }}>
      {getNavigationItems(permissions as any).map(({ label, icon: Icon, path, id, children }) => {
        if (children) {
          return <CollapseLink parentLink={{ label, icon: Icon, path, id, children }} handleDrawer={toggleDrawer} key={id} />;
        } else {
          return (
            <StyledLink to={path} onClick={toggleDrawer} key={id}>
              <ListItem button>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={label.toLowerCase()} />
              </ListItem>
            </StyledLink>
          );
        }
      })}
    </List>
  );

  return (
    <SwipeableDrawer open={isDrawerOpen} onClose={toggleDrawer} onOpen={toggleDrawer}>
      {list}
    </SwipeableDrawer>
  );
};
export default Drawer;
